import React from "react"
// import HubspotForm from 'react-hubspot-form'
import Iframe from 'react-iframe'

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
    <Layout>
        <SEO title="Kontakt" />
        <div className="pageHero">
            <div className="container section">
                <div className="row">
                    <div className="col-xs-12 center-xs">
                        <h2 className="pageHeroTitle">Zögern Sie nicht und ergreifen Sie jetzt die Chance.</h2>
                        <p className="pageHeroTruncate">Können Sie sich vorstellen, dass Sie in nur wenigen Tagen Ihr Unternehmen krisenfest machen können? Dann sollten Sie jetzt die richtigen Schritte unternehmen. Sollten Sie noch Zweifel haben, sollten wir darüber sprechen. Abwarten ist keine Option, es geht um Ihr Unternehmen!</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-sm-8 col-sm-offset-2">
                    <Iframe url="/feedback/" className="iframeContainer" />
                </div>
            </div>
        </div>
    </Layout>
)

export default ContactPage
